<!-- 多设备 -->
<template>
  <div class="device">
    <div v-if="list_show">
      <load-more
        :pageIndex="pageIndex"
        :pageSize="pageSize"
        :totalCount="totalCount"
        @loadmore="loadmore"
      >
        <div
          class="device-item"
          v-for="(item, index) in device_list"
          :key="index"
          @click="to_power(item)"
        >
          <h3>{{ item.charger ? item.charger.location : "" }}</h3>
          <p class="time">剩余时间：{{ item.hour }}{{ item.min }}</p>
          <p class="num">
            编号：{{ item.charger_id }} <i class="line"></i> 端口：<span class="port">{{
              item.port_id
            }}</span>
          </p>
          <div class="money">
            实付：<span>￥{{ item.money }}</span>
          </div>
        </div>
      </load-more>
    </div>
    <div class="no-list" v-else>
      <img
        class="no-img"
        src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/res/images/202106/25/chongdian/2021629-101722.png"
      />
      <p class="no-txt">暂无设备哦~</p>
    </div>
  </div>
</template>

<script>
import { getData } from "@/api/user";
export default {
  data() {
    return {
      device_list: [],
      pageIndex: 1,
      pageSize: 10,
      totalCount: 0,
      list_show: true,
    };
  },
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#f7f7f7");
  },
  created() {
    this.get_device();
  },
  methods: {
    get_device() {
      getData({
        url: `api/charging/charger/charger_status?page=${this.pageIndex}`,
        data: {},
      }).then((res) => {
        var data = res.data.data.data;
        if (res.data.code === 200) {
          this.pageSize = data.per_page;
          this.totalCount = data.total;
          let list = data.data;
          if (list && list.length > 0) {
            list.forEach((v) => {
              v.hour = v.surplus_hour == 0 ? "" : v.surplus_hour;
              v.min = v.surplus_min == 0 ? "" : v.surplus_min;
            });
          }
          if (this.pageIndex == 1) {
            this.device_list = list;
            this.list_show = list.length <= 0 ? false : true;
          } else {
            this.device_list = this.device_list.concat(list);
          }
        } else if (res.data.code === 422) {
          this.$toast(res.data.data.msg);
        }
      });
    },
    loadmore(pageIndex) {
      this.pageIndex = pageIndex;
      this.get_device();
    },
    to_power(item) {
      this.$router.push({ name: "power", query: { id: item.id } });
    },
  },
};
</script>

<style lang="less">
.device {
  padding: 16px 12px;

  .device-item {
    position: relative;
    margin-bottom: 12px;
    padding: 16px;
    background: #fff;
    border-radius: 5px;
    font-size: 14px;

    h3 {
      margin-bottom: 8px;
      font-size: 16px;
      font-weight: 600;
      line-height: 16px;
      color: #333;
      padding-right: 100px;
    }
    .time {
      margin-bottom: 28px;
      font-size: 14px;
      line-height: 20px;
      color: #3dc48d;
    }
    .num {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 20px;
      color: #666;
    }
    .line {
      height: 12px;
      width: 1px;
      margin: 0 18px;
      background: #d0d0d0;
    }
    .port {
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      color: #ffae15;
    }
    .money {
      display: flex;
      align-items: center;
      position: absolute;
      right: 17px;
      top: 19px;
      font-size: 14px;
      color: #666;
      span {
        font-size: 16px;
        font-weight: 600;
        color: #ffae15;
      }
    }
  }
}
</style>